import { Vector3 } from 'three'

export const navLinks = [
  {
    id: 'home',
    title: 'Home',
    index: 0,
  },
  {
    id: 'about',
    title: 'About',
    index: 1,
  },
  {
    id: 'team',
    title: 'Team',
    index: 2,
  },
  {
    id: 'game',
    title: 'Game',
    index: 3,
  },
  {
    id: 'contact',
    title: 'Contact',
    index: 4,
  },
]

const sections = [
  {
    id: 0,
    label: 'ABOUT GAME SHIFT',
    text: 'CLICK TO DISCOVER MORE ABOUT GAME SHIFT.',
    position: new Vector3(1.8, 1, 1),
    clicked: false,
  },
  {
    id: 1,
    label: 'WHO ARE WE',
    text: 'CLICK TO MEET OUR TEAM.',
    position: new Vector3(0.5, 0.8, -1.6),
    clicked: false,
  },
  {
    id: 2,
    label: 'CONTACT',
    text: 'CLICK TO CONTACT US.',
    position: new Vector3(1.6, -0.8, -0.7),
    clicked: false,
  },
  {
    id: 3,
    label: 'ABOUT CHECK UP TOP',
    text: 'CLICK TO DISCOVER MORE ABOUT CHECK UP TOP.',
    position: new Vector3(2, 1.5, 0.7),
    clicked: false,
  },
  // {
  //   id: 4,
  //   label: 'COME HERE PLEASE',
  //   text: 'WANNA HAVE FUN EXPERIENCE?',
  //   position: new Vector3(1.55, 0.3, -0.8),

  //   clicked: false,
  // },
]

const members = [
  {
    firstname: 'Hugh',
    name: 'Hugh Holmes Jr',
    photo: './members/hugh.jpg',
    title: 'CEO & Founder',
    email: 'hugh.holmesjr@gmail.com',
    category: 'CEO',
  },
  {
    firstname: 'Barron',
    name: 'Barron Botts',
    photo: './members/baron.jpg',
    title: 'Lead Programmer',
    email: 'barron.botts@gmail.com',
    category: 'Leads',
  },
  {
    firstname: 'Kyle',
    name: 'Kyle Evitts',
    photo: '../members/kyle.png',
    title: 'Lead 3D Artist',
    email: '',
    category: 'Leads',
  },
  {
    firstname: 'Najlae',
    name: 'Najlae Abarghache',
    photo: '',
    title: 'Programmer | GS Website Developer',
    email: 'abarghachenajlae@gmail.com',
    category: 'Team Members',
  },
  {
    firstname: 'Rheanna',
    name: 'Rheanna King',
    photo: '../members/ray.jpg',
    title: 'Concept Artist',
    email: '',
    category: 'Team Members',
  },
  {
    firstname: 'Miguel',
    name: 'Miguel Ruiz',
    photo: '../members/miguel.png',
    title: 'Lead 2D Art | UI Artist',
    email: 'redarch921@gmail.com',
    category: 'Leads',
  },
  {
    firstname: 'Soji',
    name: 'Soji Adenusi',
    photo: '../members/soji.jpg',
    title: 'Game Programmer',
    email: 'adesoji.adenusi22@gmail.com',
    category: 'Team Members',
  },
  {
    firstname: 'Jasper',
    name: 'Jasper Wu',
    photo: '',
    title: 'Animator',
    email: 'wuwenku@gmail.com',
    category: 'Team Members',
  },
  {
    firstname: 'Micah',
    name: 'Micah',
    photo: '../members/micah.jpg',
    title: 'Lead Animator',
    email: '',
    category: 'Leads',
  },
  {
    firstname: 'Justin',
    name: 'Justin Weber Hernandez',
    photo: '',
    title: 'Programmer',
    email: 'justinweber193@gmail.com',
    category: 'Team Members',
  },
  {
    firstname: 'Felicia',
    name: 'Felicia Edet',
    photo: '../members/felicia.jpg',
    title: 'Concept Artist',
    email: 'fefe.edet@gmail.com',
    category: 'Team Members',
  },
  {
    firstname: 'Christopher',
    name: 'Christopher Negus',
    photo: '../members/christopher.jpg',
    title: '3D Environment | Prop Artist',
    email: 'maestro.gamer@yahoo.com',
    category: 'Team Members',
  },
  {
    firstname: 'William',
    name: 'William Capellan',
    photo: '',
    title: '3D Modeler',
    email: 'williamcapellan@gmail.com',
    category: 'Team Members',
  },
  {
    firstname: 'Antonio',
    name: ' Antonio Marrero',
    photo: '',
    title: 'Animator',
    email: ' antoniomr00@gmail.com ',
    category: 'Team Members',
  },
  {
    firstname: 'Ava',
    name: 'Ava Smith',
    photo: '../members/ava.jpg',
    title: 'Programmer',
    email: 'smi01868@umn.edu',
    category: 'Team Members',
  },
  {
    firstname: 'Kristian',
    name: 'Kristian West',
    photo: '../members/kristian.jpg',
    title: 'Animator',
    email: 'kris485836@gmail.com',
    category: 'Team Members',
  },
]

const quotes = [
  {
    id: 0,
    quote:
      'To breathe innovation and imagination into video games; We will better the video game market in order to inspire and impact the game industry.',
    name: 'wearegameshift',
    designation: 'Team',
    company: 'Game Shift',
    image: '../logo.png',
    goto: 'https://www.instagram.com/wearegameshift',
  },
  {
    id: 1,
    quote:
      'We envision ourselves as becoming a beacon for quality AAA titles. Each one of our projects should be revered as a unique product designed by quality and care.',
    name: '_hhjr',
    designation: 'CEO',
    company: 'Game Shift',
    image: '../members/hugh.jpg',
    goto: 'https://www.instagram.com/_hhjr',
  },
]

export { members, quotes, sections }
