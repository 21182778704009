import { AboutGame } from '../AboutGame/AboutGame'
import About from '../About/AboutUs'
import { Contact } from '../Contact/Contact'
import Team from '../Team/Team'
import './Panel.css'

export const Panel = ({ pointId }) => {
  const getContent = () => {
    switch (pointId) {
      case 0:
        return <About />
      case 1:
        return <Team />
      case 2:
        return <Contact />
      case 3:
        return <AboutGame />
      default:
        return null
    }
  }
  return (
    <>
      <div className='content'>{getContent()}</div>
    </>
  )
}
