import { useRef, useEffect, useState, startTransition } from 'react'
import { sections } from '../../constants.js'
import { Vector3 } from 'three'
import { useScene } from '../../ReactContext/useScene.jsx'
import { Panel } from '../../Panel/Panel.jsx'
import './Points.css'

export const Points = ({ camera, raycaster, sceneReady }) => {
  const { scene } = useScene()
  const [arePointsVisible, setArePointsVisible] = useState(false)
  const [pointsClicked, setPointsClicked] = useState(0)
  const [isAnyPointClicked, setIsAnyPointClicked] = useState(false)

  useEffect(() => {
    setArePointsVisible(sceneReady)
  }, [sceneReady])

  useEffect(() => {
    // Update the isAnyPointClicked state when pointsClicked changes
    setIsAnyPointClicked(pointsClicked > 0)
  }, [pointsClicked])

  return (
    <>
      {/* {arePointsVisible && */}
      {sections.map((point, index) => (
        <Point
          key={index}
          point={point}
          camera={camera}
          raycaster={raycaster}
          scene={scene}
          sceneReady={sceneReady}
          pointsClicked={pointsClicked}
          setPointsClicked={setPointsClicked}
          isAnyPointClicked={isAnyPointClicked}
        />
      ))}
    </>
  )
}

export const Point = ({
  point,
  camera,
  raycaster,
  scene,
  pointsClicked,
  setPointsClicked,
  isAnyPointClicked,
}) => {
  const pointRef = useRef()
  const panelRef = useRef()

  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  }

  const [isSceneAvailable, setIsSceneAvailable] = useState(false)
  const [isClicked, setIsClicked] = useState(false)

  useEffect(() => {
    if (scene) {
      setIsSceneAvailable(true)
    }
  }, [scene])

  const updatePosition = () => {
    if (!point.position || !camera) {
      console.log('Point position or camera is not available')
      return
    }
    let screenPosition = new Vector3()
    screenPosition = point.position.clone()

    if (!camera.isPerspectiveCamera) {
      console.log('Invalid camera type:', camera)
      return
    }
    screenPosition.project(camera)

    raycaster.setFromCamera(screenPosition, camera)

    // Check if the pointRef.current is available and has a parent before accessing it
    if (pointRef.current && scene) {
      const intersects = raycaster.intersectObjects(scene.children, true)
      // console.log(intersects.length)

      if (intersects.length === 0) {
        pointRef.current?.classList.add('visible')
      } else {
        const intersectionDistance = intersects[0].distance
        const pointDistance = point.position.distanceTo(camera.position)

        if (intersectionDistance < pointDistance) {
          pointRef.current?.classList.remove('visible')
        }
      }

      const translateX = screenPosition.x * sizes.width * 0.5
      const translateY = -screenPosition.y * sizes.height * 0.5

      pointRef.current.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
    } else {
      pointRef.current?.classList.remove('visible')
    }
  }

  useEffect(() => {
    const animate = () => {
      if (isAnyPointClicked) {
        // If any point is clicked and the current point is not clicked, make it invisible
        pointRef.current?.classList.remove('visible')
      } else {
        // Update position as before
        updatePosition()
      }
      requestAnimationFrame(animate)
    }

    animate()
    return () => {
      // Clean up the animation frame when the component is unmounted
      cancelAnimationFrame(animate)
    }
  }, [isSceneAvailable, camera, scene, isAnyPointClicked, isClicked])

  const clickedLabel = () => {
    if (!isClicked) {
      setPointsClicked((prevPointsClicked) => prevPointsClicked + 1)

      if (pointRef.current.getAttribute('data-id') === '4') {
        // redirect to another page (component "Experience")
        startTransition(() => {
          console.log('navigate to experience')
          navigate('/experience')
        })
      } else {
        setIsClicked(true)
        panelRef.current?.classList.add('clicked')
        if (pointRef.current.getAttribute('data-id') === '3') {
          panelRef.current?.classList.add('dark-background')
        }
      }
    }
  }

  const closePanel = () => {
    if (isClicked) {
      setIsClicked(false)
      setPointsClicked((prevPointsClicked) => prevPointsClicked - 1)
      console.log(pointsClicked)
      if (pointsClicked - 1 === 0) {
        panelRef.current?.classList.remove('clicked')
      }
    }
  }

  return (
    <>
      <div
        className={`point visible ${isAnyPointClicked ? 'non-clickable' : ''}`}
        data-id={point.id}
        ref={pointRef}
      >
        <div className='label' onClick={clickedLabel}>
          {point.label}
        </div>
        <div className='custom-text'>{point.text}</div>
      </div>
      <div className='panel ' ref={panelRef}>
        <div className='close' onClick={closePanel}></div>
        <Panel pointId={point.id} />
      </div>
    </>
  )
}
