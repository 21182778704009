import './AboutGame.css'

export const AboutGame = () => {
  return (
    <>
      <div className='background'></div>
      <div className='container col-10 col-md-4'>
        <div className='row'>
          <h2 className='col content-title'>
            Who wants to play some one on one ?
          </h2>
        </div>
        <div className='row game-details'>
          <p>
            Check up Top is a one on one Basketball game for console devices
            where you use multiple moves to win the game and build up skill
            points. Players will be able to play through easy, normal, and hard
            difficulty levels, with the option to play online. Players can
            customize their characters' appearances as well as unlock different
            items and stages.
          </p>
        </div>
        <div className='row small-quote'>
          <p>"where tekken and nba street meet"</p>
        </div>
      </div>
    </>
  )
}
