import { useEffect, useState } from 'react'
import { Member } from './Member/Member'
import { members } from '../constants'
import './Team.css'

export default function Team() {
  const [expandedCategory, setExpandedCategory] = useState(null)
  const [isAnyCardOpened, setIsAnyCardOpened] = useState(false)
  const [CardsOpened, setCardsOpened] = useState(0)

  const handleCategoryClick = (category) => {
    if (expandedCategory === category) {
      setExpandedCategory(null)
    } else {
      setExpandedCategory(category)
    }
  }

  useEffect(() => {
    setIsAnyCardOpened(CardsOpened > 0)
  }, [CardsOpened])
  return (
    <>
      <div className='container'>
        <p
          className={`content-title ${isAnyCardOpened ? 'non-clickable' : ''}`}
        >
          Our Team
        </p>

        <div className='categories'>
          <div
            className={`category-label ${
              expandedCategory === 'CEO' ? 'expanded' : ''
            } ${isAnyCardOpened ? 'non-clickable' : ''}`}
            onClick={() => handleCategoryClick('CEO')}
          >
            CEO
          </div>
          <div
            className={`category-label ${
              expandedCategory === 'Leads' ? 'expanded' : ''
            } ${isAnyCardOpened ? 'non-clickable' : ''}`}
            onClick={() => handleCategoryClick('Leads')}
          >
            Leads
          </div>
          <div
            className={`category-label ${
              expandedCategory === 'Team Members' ? 'expanded' : ''
            } ${isAnyCardOpened ? 'non-clickable' : ''}`}
            onClick={() => handleCategoryClick('Team Members')}
          >
            Team Members
          </div>
        </div>
        {expandedCategory && (
          <div className='container text-center'>
            <div className='row justify-content-center align-items-center'>
              {members
                .filter((member) => member.category === expandedCategory)
                .map((member) => (
                  <div className='col-lg-2 col-sm-4 col-6 ' key={member.name}>
                    <Member
                      member={member}
                      setCardsOpened={setCardsOpened}
                      CardsOpened={CardsOpened}
                      isAnyCardOpened={isAnyCardOpened}
                      style={{ paddingTop: '2.5rem' }}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
