import { Canvas } from '@react-three/fiber'
import { Points } from '../Home/Points/Points.jsx'
import Home from '../Home/Home.jsx'
import { gsap } from 'gsap'
import {
  LoadingManager,
  Mesh,
  PlaneGeometry,
  Raycaster,
  ShaderMaterial,
  TextureLoader,
} from 'three'
import { PerspectiveCamera } from 'three'
import { SceneProvider } from '../ReactContext/useScene.jsx'
import './Main.css'
import { useRef, useState } from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

export function Main() {
  const camera = new PerspectiveCamera(
    85,
    window.innerWidth / window.innerHeight,
    0.1,
    100
  )
  camera.position.set(4, 1, -1)

  /**
   * Overlay
   */
  const overlayGeometry = new PlaneGeometry(2, 2, 1, 1)
  const overlayMaterial = new ShaderMaterial({
    // wireframe: true,
    transparent: true,
    uniforms: {
      uAlpha: { value: 1 },
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `,
  })
  const overlay = new Mesh(overlayGeometry, overlayMaterial)

  const loadingBarRef = useRef()
  const [sceneReady, setSceneReady] = useState(false)

  const loadingManager = new LoadingManager(
    // Loaded
    () => {
      // Wait a little
      window.setTimeout(() => {
        // Animate overlay
        gsap.to(overlayMaterial.uniforms.uAlpha, {
          duration: 3,
          value: 0,
          delay: 1,
        })

        // Update loadingBarElement
        if (loadingBarRef.current) {
          loadingBarRef.current.classList.add('ended')
          loadingBarRef.current.style.transform = ''
        }
      }, 500)
      window.setTimeout(() => {
        setSceneReady(true)
      }, 4000)
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) => {
      // Calculate the progress and update the loadingBarElement
      const progressRatio = itemsLoaded / itemsTotal
      // console.log(progressRatio)
      if (loadingBarRef.current) {
        loadingBarRef.current.style.transform = `scaleX(${progressRatio})`
        // console.log(loadingBarRef)
      }
    }
  )
  const dracoLoader = new DRACOLoader()
  dracoLoader.setDecoderPath('draco/')
  const gltfLoader = new GLTFLoader(loadingManager)
  gltfLoader.setDRACOLoader(dracoLoader)
  const textureLoader = new TextureLoader(loadingManager)
  const raycaster = new Raycaster()

  return (
    <>
      <SceneProvider>
        <div className='loading-bar' ref={loadingBarRef}></div>
        <Points camera={camera} raycaster={raycaster} sceneReady={sceneReady} />
        <Canvas className='webgl' shadows camera={camera}>
          {/* <Experience /> */}
          <Home
            overlay={overlay}
            gltfLoader={gltfLoader}
            textureLoader={textureLoader}
          />
        </Canvas>
      </SceneProvider>
    </>
  )
}
