import { Modal, useMantineTheme } from '@mantine/core'
import { useEffect, useRef, useState } from 'react'
import './Member.css'

export const Member = ({
  member,
  setCardsOpened,
  CardsOpened,
  isAnyCardOpened,
}) => {
  const [hovered, setHovered] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const cardRef = useRef()

  const handleBallClick = (event) => {
    setModalOpened(true)
    setCardsOpened((prevCardsOpened) => prevCardsOpened + 1)
    cardRef.current?.classList.add('clicked')
  }

  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  const handleCloseModal = (event) => {
    setModalOpened(false)
    setCardsOpened((prevCardsOpened) => prevCardsOpened - 1)
    cardRef.current?.classList.remove('clicked')
  }

  const renderMemberPhoto = () => {
    if (member.photo) {
      return (
        <>
          <img
            src={member.photo}
            alt='Avatar'
            className='card__image img-fluid'
          />
        </>
      )
    }
    return null
  }

  const renderEmailButton = () => {
    if (member.email) {
      return (
        <>
          <a className='btn draw-border btn-sm' href={`mailto:${member.email}`}>
            Email
          </a>
        </>
      )
    }
    return null
  }

  return (
    <>
      <img
        className={`ball-member ${isAnyCardOpened ? 'non-clickable' : ''}`}
        src='../textures/member-ball/ball.png'
        onClick={handleBallClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      {hovered && (
        <div
          className='ball-hovered'
          style={{
            display: hovered ? 'block' : 'none',
          }}
        >
          <p>{member.firstname}</p>
        </div>
      )}
      <div
        className={`card container col-md-2 col-sm-4 col-9 mh-50 ${
          modalOpened ? 'clicked' : ''
        }`}
        ref={cardRef}
      >
        <div className='closeCard' onClick={handleCloseModal}></div>
        {renderMemberPhoto()}
        <p className='card__name'>{member.name}</p>
        <p className='title'>{member.title}</p>
        {renderEmailButton()}
      </div>
    </>
  )
}
