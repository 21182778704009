import React, { useRef, useState } from 'react'
import './Contact.css'
import { sendEmail } from '../api/EmailApi'

export const Contact = () => {
  const formRef = useRef()
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  })

  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    const { target } = e
    const { name, value } = target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      // Call the sendEmail function from EmailApi.js
      await sendEmail(form)
      // Reset the form and loading state after successful submission
      setForm({
        name: '',
        email: '',
        message: '',
      })
      setLoading(false)
      // Optionally, show a success message to the user
      alert('Email sent successfully!')
    } catch (error) {
      // Handle errors here, e.g., show an error message to the user
      console.error('Error sending email:', error)
      setLoading(false)
    }
  }

  return (
    <>
      <div className='container col-10 col-sm-4'>
        <p className='content-title'>Get in touch</p>

        <form ref={formRef} onSubmit={handleSubmit} className='mt-5'>
          <label className='row mb-3'>
            <input
              type='text'
              name='name'
              value={form.name}
              onChange={handleChange}
              placeholder='Name..'
              className='contact-placeholder'
            />
          </label>
          <label className='row  mb-3'>
            <input
              type='email'
              name='email'
              value={form.email}
              onChange={handleChange}
              placeholder='Email..'
              className='contact-placeholder'
            />
          </label>
          <label className='row  mb-3'>
            <textarea
              rows={7}
              name='message'
              value={form.message}
              onChange={handleChange}
              placeholder='Message..'
              className='contact-placeholder'
            />
          </label>
          <div className='row'>
            <button type='submit' className='draw-border contact-button'>
              {loading ? 'Sending...' : 'Send'}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
