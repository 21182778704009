import { createContext, useContext, useState } from 'react'

// Create the context
const SceneContext = createContext()

// Create a custom provider component for the scene
export const SceneProvider = ({ children }) => {
  const [scene, setScene] = useState(null)

  return (
    <SceneContext.Provider value={{ scene, setScene }}>
      {children}
    </SceneContext.Provider>
  )
}

// Export a custom hook to access the context value
export const useScene = () => useContext(SceneContext)

export default SceneContext
