import { quotes } from '../constants'
import './About.css'

export const Card = ({ quoteData, index }) => {
  const isActive = index === 0 // Check if the index is 0
  return (
    <div className={`carousel-item ${isActive ? 'active' : ''}`}>
      <div className='container pt-5'>
        <p className='double-quotes'>"</p>
        <div className='container'>
          <p className='quote-text'>{quoteData.quote}</p>
          <div className='quote-info'>
            <div className='container'>
              <a className='social-media' href={quoteData.goto}>
                <p className='quote-tagname'>
                  <span className='at'>@</span>
                  {quoteData.name}
                </p>
              </a>
              <p className='quote-designation'>
                {quoteData.designation} of {quoteData.company}
              </p>
            </div>
            <a className='social-media' href={quoteData.goto}>
              <img
                src={quoteData.image}
                alt=''
                className='full-rounded-cover-image'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function About() {
  return (
    <>
      <div className='container col-10 col-md-6 col-lg-5' >
        <p className=' row justify-content-center align-self-center content-title'>
          Our vision & mission
        </p>
        <div
          id='carouselExample'
          className='carousel slide pt-5'
          data-bs-ride='carousel'
        >
          <div className='carousel-inner  '>
            {quotes.map((quoteData, index) => (
              <Card key={index} quoteData={quoteData} index={quoteData.id} />
            ))}
          </div>
        </div>
        <button
          className='custom-carousel-control-prev'
          type='button'
          data-bs-target='#carouselExample'
          data-bs-slide='prev'
        >
          <span
            className='carousel-control-prev-icon'
            aria-hidden='true'
          ></span>
          <span className='visually-hidden'>Previous</span>
        </button>
        <button
          className='custom-carousel-control-next'
          type='button'
          data-bs-target='#carouselExample'
          data-bs-slide='next'
        >
          <span
            className='carousel-control-next-icon'
            aria-hidden='true'
          ></span>
          <span className='visually-hidden'>Next</span>
        </button>
      </div>
    </>
  )
}
