import React, { useRef, useState, useEffect, Suspense } from 'react'
import { useThree } from '@react-three/fiber'
import {
  useGLTF,
  Preload,
  useTexture,
  Environment,
  OrbitControls,
} from '@react-three/drei'
import { MeshStandardMaterial, Raycaster, Vector3 } from 'three'
import { Perf } from 'r3f-perf'
import { SceneProvider, useScene } from '../ReactContext/useScene.jsx'

export default function Home({ overlay, gltfLoader, textureLoader }) {
  const { scene } = useThree()
  const { setScene } = useScene() // Get access to the setScene function from the context

  // Set the scene value in the SceneProvider
  useEffect(() => {
    setScene(scene)
  }, [scene, setScene])

  scene.add(overlay)

  /**
   * Loaders to save progress in LoadingManager
   */
  gltfLoader.load('./models/checkuptop.glb', (gltf) => {
    // Here you can handle the loaded model, if needed
  })
  textureLoader.load('textures/environmentMaps/basketball-court.hdr')

  /**
   * Model
   */
  const ball = useGLTF('./models/checkuptop.glb')
  const colorTexture = useTexture(
    './textures/checkuptop_ball/GameShift Ball 4_Base_color.jpg'
  )
  const normalDirectXTexture = useTexture(
    './textures/checkuptop_ball/GameShift Ball 4_Normal_DirectX.jpg'
  )
  const ambientOcclusionTexture = useTexture(
    './textures/checkuptop_ball/GameShift Ball 4_Mixed_AO.png'
  )
  const heightTexture = useTexture(
    './textures/checkuptop_ball/GameShift Ball 4_Height.jpg'
  )
  const metalnessTexture = useTexture(
    './textures/checkuptop_ball/GameShift Ball 4_Metallic.png'
  )
  const roughnessTexture = useTexture(
    './textures/checkuptop_ball/GameShift Ball 4_Roughness.jpg'
  )

  // Set the flipY property for all textures
  colorTexture.flipY = false
  normalDirectXTexture.flipY = false
  ambientOcclusionTexture.flipY = false
  heightTexture.flipY = false
  metalnessTexture.flipY = false
  roughnessTexture.flipY = false

  const ballRef = useRef()
  const [ballObject, setBallObject] = useState(null)

  useEffect(() => {
    // Retrieve the cloned mesh from the GLTF model
    const clonedBallObject = ball.scene.children[0].clone()

    // Create a new material for the cloned mesh
    const material = new MeshStandardMaterial({
      map: colorTexture,
      normalMap: normalDirectXTexture,
      aoMap: ambientOcclusionTexture,
      displacementMap: heightTexture,
      displacementScale: 0.1,
      metalnessMap: metalnessTexture,
      roughnessMap: roughnessTexture,
    })
    // Assign the material to the cloned mesh
    clonedBallObject.material = material

    // console.log(clonedBallObject.position)

    clonedBallObject.rotateZ(Math.PI / 3)
    clonedBallObject.rotateY(Math.PI / 2)

    // Set the cloned mesh as the state
    setBallObject(clonedBallObject)
  }, [
    ball,
    colorTexture,
    normalDirectXTexture,
    ambientOcclusionTexture,
    heightTexture,
    metalnessTexture,
    roughnessTexture,
  ])

  return (
    <>
      <SceneProvider>
        {/* <Perf position='top-left' /> */}

        <ambientLight intensity={0.25} />
        <directionalLight position={[0, 0, 0.05]} />

        <OrbitControls makeDefault />
        <Suspense fallback={null}>
          <Preload all />
        </Suspense>

        <mesh
          castShadow
          receiveShadow
          rotation-y={0}
          position={[0, -1.5, 0]}
          scale={[0.35, 0.35, 0.35]}
        >
          {ballObject && <primitive object={ballObject} ref={ballRef} />}
        </mesh>
        <Environment
          background
          files={'textures/environmentMaps/basketball-court.hdr'}
        />
      </SceneProvider>
    </>
  )
}
